<script setup>

const { data } = useFetch('/api/users/pending-review')

</script>

<template>
  <v-list-item
    prepend-icon="mdi-account-group-outline"
    title="Users"
    to="/members/users"
  >
    <template
      #append
      v-if="data?.length"
    >
      <v-badge
        color="error"
        :content="data.length"
        max="9"
        inline
      />
    </template>
  </v-list-item>
</template>
